module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Roboto Condensed","file":"https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap"},{"name":"Roboto Slab","file":"https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap"},{"name":"Roboto Serif","file":"https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fermento Bolognina","short_name":"Fermento","start_url":"/","display":"standalone","icon":"src/images/ex.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a12de008285b674c88372c5edd79f391"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["it","en"],"defaultLanguage":"it","redirect":true,"redirectComponent":"/opt/build/repo/src/components/Redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
