import React from "react"
import { useEffect } from "react"

const Redirect = () => {
  useEffect(() => {
    if (window.location.hash.includes('_token=')) {
      window.location.replace('/admin/' + window.location.hash)
    }
  })

  return <></>;
};

export default Redirect
